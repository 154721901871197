import { graphql } from 'gatsby';
import React from 'react';
import {
  HeadWrapper,
  largestContentfullPaintImg,
} from '../../layout/HeadWrapper';
import Layout from '../../layout/index';
import { useTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { morphism } from 'morphism';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { schemaHeroVideo } from '../../morphismSchema/templates/schemaHeroVideo';
import { HeroVideo } from '@payfit/growth-platform-brand-system-v2/templates/HeroVideo';

export const Head = ({ data, pageContext, location }: any) => {
  const heroProps = data?.datoCmsNewFeaturesWallPage?.hero?.[0];
  const largestContentfullPaintImg: largestContentfullPaintImg =
    heroProps?.image?.gatsbyImageData?.images?.fallback ||
    heroProps?.vimeoImage?.gatsbyImageData?.images?.fallback ||
    null;
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="NewFeaturesWall"
      dataName={'datoCmsNewFeaturesWallPage'}
      largestContentfullPaintImg={largestContentfullPaintImg}
    />
  );
};

export const query = graphql`
  query DatoNewFeaturesWallPage($locale: String, $id: String) {
    datoCmsNewFeaturesWallPage(locale: $locale, id: { eq: $id }) {
      ...NewFeaturesWallPage
    }
    allDatoCmsNewFeaturePage(
      locale: $locale
      filter: { locales: { eq: $locale } }
      sort: { _allDateLocales: { value: DESC } }
    ) {
      nodes {
        id
        slug
        date
        doNotCreatePage
        hero {
          image {
            url
            gatsbyImageData(
              width: 446
              breakpoints: [480, 768, 1024, 1280]
              imgixParams: { w: "446", dpr: 1, q: 90 }
            )
            alt
            width
            height
          }
          filterTags {
            title
            tagType
          }
        }
        metaFields {
          description
          title
          image {
            url
          }
        }
      }
    }
  }
`;

const NewFeaturesWall = ({ data, pageContext }: any) => {
  const { trackingPage } = useTracking();

  const {
    functionalityLabel,
    userFilter,
    userLabel,
    seeMoreButtonLabel,
    noResultText,
    ...doc
  }: any = data.datoCmsNewFeaturesWallPage;

  const _data = morphism(schemaBaseTemplate, {
    ...doc,
    pageContext,
  });
  const hero = _data?.hero;

  return (
    <Layout pageContext={pageContext} data={doc} templateName="NewFeaturesWall">
      {hero && (
        <HeroVideo {...morphism(schemaHeroVideo, { ...hero, trackingPage })} />
      )}
      {_data?.blocks.length > 0 && (
        <TemplateBlocksZone
          {..._data}
          doc={{
            ...doc,
            allDatoCmsNewFeaturePage: data?.allDatoCmsNewFeaturePage,
          }}
        />
      )}
    </Layout>
  );
};

export default NewFeaturesWall;
